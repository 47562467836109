<template>
  <div class="container">
    <!-- Page Title -->
    <div class="page-title pl-0" v-if="user">
      <h1>{{ user.name }}</h1>
      <h4>{{ user.role.title }}</h4>
      <p class="text-muted mt-2 mb-0" v-if="isDoppelganger">
        <i class="fa fa-toolbox"></i> Viewing jobs for {{ user.technician.technician }}
      </p>
    </div>

    <!-- Links -->
    <div class="row">
      <div class="col-md-12 mb-2">
        <!-- Job search -->
        <job-search-card v-if="isJobManager"></job-search-card>

        <!-- Next Job -->
        <router-link v-if="isTechnician && (jobs && jobs.length > 0)" :to="{name: 'job', params: {jobId:jobs[0].id}}" class="card card-body card-link">
          <dl>
            <dt>Next job</dt>
            <dd>{{ jobs[0].start_time }} - {{ jobs[0].end_time }}
              <span class="font-weight-normal ml-2" v-if="jobs[0].first_name || jobs[0].second_name">
                {{ jobs[0].first_name }} {{ jobs[0].second_name }}
              </span>
            </dd>
          </dl>
        </router-link>

        <div class="card-grid grid-col-sm-2" :class="gridClass">
          <div v-for="(nav, idx) in visibleMainNavigation" :key="`main-nav-${idx}`" class="card-link-container">
            <router-link :to="nav.route" class="card card-body card-link vertical">
              <span v-if="nav.badge" :class="nav.badge.classList">{{ nav.badge.text }}</span>
              <div :is="nav.icon" size="2x"/>
              <h5>{{ nav.title }}</h5>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Policies and Procedures -->
      <a href="https://sites.google.com/wefix.co.uk/trainingandcompliance/home" target="_blank"
         class="btn btn-solid-secondary btn-rounded m-auto px-4 py-2 text-sm icon-right">
        Training & Compliance <i class="fa fa-external-link"></i>
      </a>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  mounted() {
    this.loadJitOrdersAwaitingRepairKits();
    if (this.isTechnician) {
      this.refreshUser().then(() => {
        this.loadTodaysJobs();
      });
    }
  },

  computed: {
    ...mapGetters([
      "isDoppelganger",
      "isJobManager",
      "isTechnician",
      "jobs",
      "user",
      "visibleMainNavigation"
    ]),

    gridClass() {
      if (this.visibleMainNavigation.length <= 2){
        return 'grid-col-md-2';
      }

      if (this.visibleMainNavigation.length > 5) {
        return 'grid-col-md-4';
      }

      return 'grid-col-md-3';
    }
  },

  methods: {
    ...mapActions([
      "displayToast",
      "loadTodaysJobs",
      "loadTodaysRepairKits",
      "loadJitOrdersAwaitingRepairKits",
      "refreshUser"
    ])
  }
}
</script>